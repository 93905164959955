import React from 'react'
import { useQuery } from '@apollo/client'
import Page404 from '@cmp/templates/Page404'
import ProductPage from '@cmp/templates/ProductPage'
import LoadingPage from '@cmp/templates/LoadingPage'
import { GET_PRODUCT_GROUP } from '../data/backend/queries'

export default ({ location, ...props }) => {
  const match = location.pathname.match(/^\/([^\/]+)\/(\d+)\/?$/)
  const [, categorySlug, id] = match || []

  const { data, loading } = useQuery(GET_PRODUCT_GROUP, {
    ssr: false,
    skip: !id,
    variables: { id }
  })

  if (loading) {
    return <LoadingPage />
  }

  if (data && data.productGroup.category.slug === categorySlug) {
    return <ProductPage {...props} pageContext={data.productGroup} />
  }

  return <Page404 />
}
